<template>
    <div class="root">
        <v-row class="mt-10">
            <v-col cols="2">
            </v-col>
            <v-col cols="8" align="center">
                <v-card elevation="0" outlined v-for="(u, index) in userList" :index="index" :key="index" class="my-2">
                    <v-card-text class=justify-center>
                        <v-row>
                            <v-col cols="2">
                                <h2> </h2>
                            </v-col>
                            <v-col cols="8">
                                <h2> <a :href="'/' + u.requester_channel_name">{{u.requester_channel_name}}</a> </h2>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="2" />
        </v-row>
    </div>  
</template>
<script>
import api from '/src/utils/api';

const FollowedBy = {
    name: 'FollowedBy',
    components: {
    },
    data() {
        return {
            userList: [],
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            // console.log("FollowedBy refresh");
            api.getCall('get-followedby-list').then(userList => {
                this.userList = userList;
            });
        },
    },
    props: ['refreshAllFriendData'],
};

export default FollowedBy;
</script>
